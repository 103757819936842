
import { Component, Vue, Prop } from 'vue-property-decorator';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';

@Component({
    name: 'ProductVersionsTable',
})
export default class ProductVersionsTable extends Vue {
    @Prop({ required: true }) private config!: Record<string, any>;
    @Prop({ required: false }) private filteredData!: Record<string, any>;

    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get columns() {
        return this.config.columns;
    }

    private get filteredTableData() {
        if (this.filteredData.length) {
            return this.filteredData;
        }

        return null;
    }

    private get tableData() {
        return this.config.tableData();
    }

    private async mounted() {
        this.loadingOverlay.start();

        try {
            await this.config.entityFetchEndpoint();
        } catch {
            this.$notification.error({
                message: this.$t('Error'),
                description: this.$t('An error occurred while fetching product versions'),
            });
        } finally {
            this.loadingOverlay.stop();
        }
    }
}
